@font-face {
    font-family: 'Work Sans';
    src: url('https://cdn2.turkiyeelektronik.com/fonts/WorkSans-Thin.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5, Opera 10+, Safari 3—5 */
  }
  @font-face {
    font-family: 'Raleway';
    src: url('https://cdn2.turkiyeelektronik.com/fonts/Raleway-Regular.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5, Opera 10+, Safari 3—5 */
  }
  
  .page{
    top:0;
    min-height: 100vh;
    width: 100%;
  }

  .center{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .preRegister{
    width: 200px;
    height: 40px;
    font-size: calc(5px + 2vmin);
    background-color:#009B77;
    color:#EFC050;
    border-radius: 15px;
  }
  
  .preRegister2{
    width: 200px;
    height: 40px;
    font-size: calc(5px + 2vmin);
    background-color:#002d4f;
    color:#99c2ff;
    border-radius: 15px;
  }
  
  .colorBgA{
    background-color: #14132e;
  }
  .colorBgB{
    background-color: #006fb1;
  }
  .colorBgC{
    background-color: #6e8f3d;
  }
  
  
  .page h1{
    font-family: 'Work Sans';
    color:#3f9bff;
    margin-bottom: 0;
  }
  .page p{
    font-family: 'Raleway';
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
    width: 80%;
    color:#99c2ff;
  }
  .colorA{
    color:#ff5010 !important;
  }

  .animated {
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
  } 
  
  @-webkit-keyframes bounce {
    0%, 20%, 40%, 60%, 80%, 100% {-webkit-transform: translateY(0);}
    50% {-webkit-transform: translateY(-15px);}
  } 
  
  @keyframes bounce { 
    0%, 20%, 40%, 60%, 80%, 100% {transform: translateY(0);}
    50% {transform: translateY(-15px);}
  } 

  .bounce { 
    -webkit-animation-name: bounce;
    animation-name: bounce;
  }

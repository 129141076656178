/**
* (c) Uygar Demir KOÇ
* 18.04.2021
*
* Revision: v1.0
*
* *** CHANGELOG ***
* 18.04.2021: Inital relase.
**/

/** START General style definations **/

.inlineBlock{
    display:inline-block;
}

/** END General style definations **/

/** START Login page styles **/

.loginPlaceholder{
    margin-top: 15px;
    height: 800px;
    border-radius: 20px 20px;
    background-color: rgba(66, 245, 108, 0.8);
}

.loginPlaceholder hr{
    margin: 0;
}

.loginWithGoogle p{
    margin: 0;
    font-size:medium;
}

.loginWithGoogle hr{
    margin-bottom: 10px;
}

.loginWithGoogle .bi-google{
    margin-right: 10px;
}

.registerPlaceholder{
    margin-top: 15px;
    height: 800px;
    border-radius: 20px 20px;
    background-color: rgba(66, 135, 245, 0.8);
}

.registerPlaceholder hr{
    margin: 0;
}

.girisTitle{
    text-align: center;
    font-size: 30px;
    margin-top: 10px;
}

.card{
    height: 370px;
    margin-top: auto;
    margin-bottom: auto;
    width: 400px;
    background-color: rgba(0,0,0,0.5) !important;
    }
    
    .social_icon span{
    font-size: 60px;
    margin-left: 10px;
    color: #FFC312;
    }
    
    .social_icon span:hover{
    color: white;
    cursor: pointer;
    }
    
    .card-header h3{
    color: white;
    }
    
    .social_icon{
    position: absolute;
    right: 20px;
    top: -45px;
    }
    
    .input-group-prepend span{
    width: 50px;
    background-color: #FFC312;
    color: black;
    border:0 !important;
    }
    
    input:focus{
    outline: 0 0 0 0  !important;
    box-shadow: 0 0 0 0 !important;
    
    }
    
    .remember{
    color: white;
    }
    
    .remember input
    {
    width: 20px;
    height: 20px;
    margin-left: 15px;
    margin-right: 5px;
    }
    
    .login_btn{
    color: black;
    background-color: #FFC312;
    width: 100px;
    }
    
    .login_btn:hover{
    color: black;
    background-color: white;
    }
/** END Login page styles **/

.errorPageMain{
    text-align: center;
    margin-top: -60px;
}

.errorPageMain ul{
    text-align: left;
}

.errorPageMain h1{
    font-size: 200%;
}

.pageVote{
    display: inline-block;
    cursor: pointer;
}
body{
    background-color:#002b42;
    color:#cfcfcf;
    padding-top:60px;
    position: relative;
    min-height: 100vh;
    width: 100%;
    height: 100%;
    padding-bottom: 50px;
}
    
.home{
    border-style:solid white; 
    border-radius:50px 50px; 
    min-height:150px;
    background-color:#007bff;
    padding: 20px;
}

.center{
    text-align: center;
}

.course{
    max-height: 300px;
    padding: 15px;
    background-color: rgba(173, 173, 173, 0.15);
}

.breadcrumb{
    margin-bottom: 45px;
}

.course img{
    border-radius: 8px;
    max-width: 100px;
    max-height: 50px;
}

.footer{
    position: absolute;
    bottom:0;
    width: 100%;
    color:white;
    padding-top: 15px;
    min-height: fit-content;
    padding:10px;
    text-align: center;
}

.courseTitleLeft{
    display: inline; padding-left: 10px;
}

.courseTitleRight{
    display: inline; padding-right: 10px;
}

.kursListeUst{
    margin-bottom: 0px;
}

.generalPaddingt{
    padding-top: 15px;
}

.generalPaddingb
{
    padding-bottom: 15px;
}

hr{
    background-color:rgb(0, 112, 163);
}

.coursePlayerContainer{
    overflow: hidden;
    position: relative;
    width: 100%;
}


.playlist{
    height: 100%;
    width: 100%;
    position: absolute;
    right: 0;
    top:0;
    background-color: indigo;
    overflow-y: scroll;
}

.playlist .card{
    width: 100%;
    height: fit-content;
}
.video{
    padding-right: 0px;
}

.playlist h1{
    font-size: 150%;
    text-align: center;
    padding-top: 10px;
}

.playlist hr {
    margin-top: 0px;
}

.courseSurvey{
    height: 40px;
    /*width: 100%;*/
    background-color: indigo;
    border: 2px solid indigo;
    border-radius: 20px 20px;
    padding:5px;
    margin-bottom:20px;
    margin-top:10px;
}

.courseSurvey span{
    width:90%;
    display: inline-block;
    text-align: center;
}

.right{
    float: right;
}

.courseDownNavigation{
    padding-bottom: 50px;
}

.buyCourse{
    border:2px solid;
    border-radius: 25px;
    height: 125px;
    width: 200px;
    padding:10px;
    text-align: center;
    right: 20px;
    z-index: 1;
    position: fixed;
    background-color: rgba(5, 50, 250, 0.825);
}

.buyCourseEndPage{
    border:2px solid;
    border-radius: 25px;
    height: 125px;
    width: 200px;
    padding:10px;
    text-align: center;
    margin: auto;
    z-index: 1;
    background-color: rgba(5, 50, 250, 0.825);
}

.buyCourse h1{
    font-size: larger;
}

.buyCourse p{
    margin: 0px;
}

.buyCourseEndPage h1{
    font-size: larger;
}

.buyCourseEndPage p{
    margin: 0px;
}

#oldprice{
    color:gray;
    text-decoration: line-through;  
}
#newprice{
    color:red;
    font-size: larger;
}

.int{
    font-size: larger;
}

.dec{
    font-size: smaller;
}

.indirim{
    margin: auto;
    width: 80%;
    text-align: center;
}

.indirim hr{
    margin-top: 0px;
    border-top:1px solid rgba(0, 0, 255, 0.522);
}
.indirim h1{
    font-size: 150%;
}

.kursAdi{
    font-size: 35px;
}

.kursBaslik hr{
    margin-top: 0px;
}

.consoleContainer{
    width: 100%;
    height: 400px;
    background-color: green;
}

.consoleMenu{
    width: 100%;
    height: 44px;
    background-color: red;
    padding-right: 5px;
    padding-left: 5px;
    padding-top:3px;
}

.consoleMenu button {
    margin-right:15px;
}
.consoleCode{
    height: 178px;
    width: 100%;
    overflow-y: scroll;
}

.consoleCode textarea{
    width: 100%;
    height: 100%;
    background-color: transparent;
}
.consoleOutput{
    background-color: indigo;
    height: 178px;
    width: 100%;
    overflow-y: scroll;
    padding:5px;
}

.lesson{
    width: 100%;
    height: fit-content;
    margin:3px;
}
.lesson button{
    margin: 3px;
    width: 100%;
}

.soruSor{
    border-radius: 10px;
    height: 50px;
    width: 100px;
    position: fixed;
    bottom: 10px;
    right: 10px;
    background-color: indigo;
    text-align: center;
    z-index: 99;
   cursor: pointer;
    /*position: fixed;*/    
}

.soruModal{
    color:black;
}
.mesajModal{
    max-width: 95%;
    min-width: 85%;
}
.sendedMessage{
    background-color: #5facff;
    margin-left: auto; 
    margin-right: 0;
    width: 80%;
    height: fit-content;
    min-height: 35px;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 15px;
    padding: 5px;
}
.recivedMessage{
    background-color: #0069d8;
    color:rgb(209, 231, 250);
    margin-left: 0; 
    margin-right: auto;
    width: 80%;
    height: fit-content;
    min-height: 35px;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 15px;
    padding: 5px;
}

.messageArea{
    max-height: 350px;
    background-color: rgb(167, 255, 226);
    padding:5px;
    border-radius: 15px;
    margin-bottom: 10px;
    overflow-y: scroll;
}

.reviewLesson{
    display: inline-block;
    margin:10px;
    width: 190px;
    height: 50px;
    background-color: #010021;
    border-radius: 15px;
    padding-top: 13px;
    padding-left: 15px;
}